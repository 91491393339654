
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { JobPostingDetailByInviteModel } from "@/domain/interviewer/job-posting-detail-by-invite-key/model/JobPostingDetailByInviteModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { ROUTE_PAGES } from "@/domain/constants/RoutePages";
import { convertSecondsToTime } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "start-interview",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let key: undefined | string = undefined;
    const isLoading = ref<boolean>(false);

    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();

    const kvkkConsent = ref(false); // KVKK onay durumu

    const jobPostingDetail = ref<JobPostingDetailByInviteModel>({});

    const getJobPostingDetail = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailByInviteModel = {
        key: key,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingDetailByInvite(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPostingDetail.value, response.getValue());
          } else {
            router.push({ name: "interviewFailed" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const submit = (values: any) => {
      router.push({ name: ROUTE_PAGES.DO_INTERVIEW, query: { key: key } });
    };

    const estimatedTime = () => {
      if (jobPostingDetail.value?.interviewQuestionCount) {
        const totalSeconds = jobPostingDetail.value.interviewQuestionCount * 30;
        return convertSecondsToTime(totalSeconds);
      }
      return "0 dakika 0 saniye";
    };

    onMounted(() => {
      key = route.query.key?.toString();
      setCurrentPageTitle(t("modals.interview.startInterview.title"));
      getJobPostingDetail();
    });

    return {
      submit,
      isLoading,
      kvkkConsent,
      jobPostingDetail,
      estimatedTime,
    };
  },
});
